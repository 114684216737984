import React from 'react';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {GalleryNavigationType, MarginBottom} from '../../../constants';
import s from './SimpleLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';

export const SimpleLayoutHeader: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const GALLERY_WIDTH = 980;
  const GALLERY_HEIGHT = 551;
  const {product} = globals;

  return (
    <header className={s.header}>
      <Cell className={s.gallery}>
        <ProductGalleryLayout
          product={product}
          layoutConfig={createLayoutConfigWithDefaults({
            marginBottom:
              /* istanbul ignore next: todo: test */ settings.navigationType === GalleryNavigationType.THUMBNAILS
                ? MarginBottom.LARGE
                : MarginBottom.SMALL,
            dimensions: {
              mainMedia: {
                widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                heightConf: {num: GALLERY_HEIGHT, unit: 'px'},
              },
              thumbnails: {
                widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                heightConf: {num: 50, unit: 'px'},
              },
            },
          })}
          {...settings}
        />
      </Cell>
    </header>
  );
};
