import React from 'react';

import {Cell} from '../Cell/Cell';
import {Navigation} from '../../Navigation/Navigation';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import classNames from 'classnames';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './SpotlightLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPageSlotIds} from '../../../constants';

export const SpotlightLayoutHeader: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const GALLERY_WIDTH = 580;
  const GALLERY_HEIGHT = 420;

  const {product} = globals;
  const headerClass = classNames(s.header, {[s.fullWidth]: settings.isFullWidth});
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};

  return (
    <div className={s.headerContainer}>
      <ProductPageTopSlot />
      <div className={headerClass}>
        {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
        <article>
          <section className={s.col}>
            <Cell className={s.gallery}>
              <ProductGalleryLayout
                product={product}
                layoutConfig={createLayoutConfigWithDefaults({
                  withDynamicHeight: true,
                  dimensions: {
                    mainMedia: {
                      widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                      heightConf: {num: GALLERY_HEIGHT, unit: 'px'},
                    },
                    thumbnails: {
                      widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                      heightConf: {num: 50, unit: 'px'},
                    },
                  },
                })}
                {...settings}
              />
            </Cell>
          </section>
          <section className={s.col}>
            <Cell>
              <ProductName name={product.name} />
            </Cell>

            {addReviewsSlotsToProductPage && <RatingSummarySlot />}

            {settings.shouldShowSku && (
              <Cell className={s.sku}>
                <ProductSku />
              </Cell>
            )}

            {renderProductPageSlots && (
              <Cell className={s.productPageSlot}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails2} />
              </Cell>
            )}

            {settings.shouldShowPrice && (
              <Cell className={s.price}>
                <ProductPrice />
              </Cell>
            )}

            {renderProductPageSlots && (
              <Cell className={s.productPageSlot}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails3} />
              </Cell>
            )}

            {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
              <Cell>
                <ProductDiscountName name={product.itemDiscount.discountRuleName} />
              </Cell>
            )}

            {settings.shouldShowProductPaymentBreakdown && (
              <Cell>
                <ProductPaymentBreakdown />
              </Cell>
            )}

            <Cell>
              <DetailsPlaceholder />
            </Cell>

            <Cell className={s.description}>
              <ProductDescription description={product.description} />
            </Cell>

            {renderProductPageSlots && (
              <Cell className={s.productPageSlotMediumSpacing}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
              </Cell>
            )}

            <Cell className={s.options}>
              <ProductOptions
                shouldShowQuantity={settings.shouldShowQuantity}
                shouldShowStockIndicator={settings.shouldShowStockIndicator}
              />
              <ProductPageButtonsContainer />
            </Cell>

            {settings.shouldShowSocialNetwork && (
              <Cell className={s.social}>
                <ProductSocial />
              </Cell>
            )}
          </section>
        </article>
      </div>
    </div>
  );
};
